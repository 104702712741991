<template>
  <div class="login">
    <div class="pb-3 login-user-logo">
      <img src="@/assets/images/UserLOGO.svg" alt="logo" />
    </div>
    <div class="card login-card">
      <div class="card-body p-4">
        <div class="login-logo">
          <img src="@/assets/images/Logo.svg" alt="logo" />
        </div>
        <form @submit.prevent="submitForm">
          <div class="mb-3">
            <label for="loginUser" class="form-label">{{
              $t(`__username`)
            }}</label>
            <input
              v-model="username"
              id="loginUser"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': is_invalid }"
              :placeholder="$t(`__enterYouUsername`)"
              required
            />
          </div>
          <div>
            <label for="loginPassword" class="form-label">{{
              $t(`__password`)
            }}</label>
            <div class="input-group">
              <input
                v-model="password"
                id="loginPassword"
                :type="show_password ? 'text' : 'password' "
                class="form-control"
                :class="{ 'is-invalid': is_invalid }"
                :placeholder="$t(`__enterYouPassword`)"
                required
              />
              <span class="input-group-text show-password" role="button" @click="showedPassword">
                <i class="bi" :class="show_password ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
              </span>
            </div>
          </div>
          <div class="text-end">
            <a
              tabindex="0"
              role="button"
              class="btn btn-link login-forgot-password px-0"
              data-bs-container="body"
              data-bs-toggle="focus"
              data-bs-placement="top"
              :data-bs-content="$t(`__forgotPasswordNote`)"
            >
              {{ $t(`__forgotPassword`) }}?
            </a>
          </div>
          <div class="text-danger text-center" v-if="is_invalid">
            {{ feedback_message }}
          </div>
          <button
            type="submit"
            class="btn btn-primary rounded-pill login-submit mt-3"
          >
            {{ $t(`__login`) }}
          </button>
        </form>
      </div>
    </div>
    <small class="p-3 d-block text-center text-dark fw-light"
      >©
        {{ new Date().getFullYear() + " " + $t("__HumetricsInc") }} All
        rights reserved.</small
    >
  </div>
</template>

<script>
import { Popover } from 'bootstrap'
import { auth } from '@/http/api/auth.js'
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      username: null,
      password: null,
      feedback_message: null,
      is_invalid: false,
      bootstrap_popover: null,
      show_password: false
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created () {
  },
  watch: {
    username: function () {
      this.resetInvalidStatus()
    },
    password: function () {
      this.resetInvalidStatus()
    }
  },
  methods: {
    ...mapActions(['getUserInfo']),
    ...mapMutations(['Loaded', 'Loading']),
    showedPassword () {
      this.show_password = !this.show_password
    },
    resetInvalidStatus () {
      this.is_invalid = false
    },
    submitForm () {
      const vm = this
      vm.Loading()
      auth
        .login({
          username: vm.username,
          password: vm.password
        })
        .then((res) => {
          const feaback = res.data
          if (res.status <= 201) {
            if (feaback.status === 'success') {
              localStorage.setItem('humetrics_user_token', feaback.token)
              if (feaback.passwordExpire) {
                vm.$router.push({ name: 'Change Password' })
              } else {
                vm.$router.push({ name: 'Dashboard' })
              }
            } else {
              vm.is_invalid = true
              vm.feedback_message = feaback.errMsg
            }
          } else {
            vm.is_invalid = true
            vm.feedback_message = feaback.errMsg
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.is_invalid = true
          vm.feedback_message = err
          vm.Loaded()
        })
    }
  },
  mounted () {
    const popoverEle = document.querySelector('.login-forgot-password')
    this.bootstrap_popover = new Popover(
      popoverEle,
      {
        container: 'body',
        trigger: 'focus'
      }
    )
  }
}
</script>

<style lang="scss">
.login {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  &-user-logo {
    max-width: 150px;
    width: 40%;
    margin: auto;
    img {
      width: 100%;
    }
  }
  &-logo {
    max-width: 250px;
    width: 70%;
    margin: 20px auto;
    img {
      width: 100%;
    }
  }
  &-forgot-password {
    text-decoration: none;
    font-size: 14px;
    &:focus {
      box-shadow: none;
    }
  }
  &-card {
    max-width: 360px;
    width: 90%;
    margin: auto;
    border-radius: 10px;
  }
  &-submit {
    width: 100%;
    color: $white;
    transition: box-shadow 0.3s ease-in-out;
    background: $linear-gradient;
    box-shadow: 0px 4px 4px $shadow-color;
    &:hover {
      color: $white;
      box-shadow: 0px 7px 7px $shadow-color;
    }
    &:focus {
      color: $white;
    }
  }
  .show-password{
    background-color: #fff;
    border: none;
    color: $gray;
  }
}
</style>
